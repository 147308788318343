import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Button, OverlayTrigger, DialogBase, Dialog, Link } from 'ui'
import { useAuth } from '@hooks/useAuth'
import {
  loginAndPreserveUrlParams,
  redirectFromSplashPage,
  showBetaAgreementModalIfUrlParamsRequireAuthorization
} from '@services/auth/path_authorization'

const BetaAuthorizationTrigger = () => {
  const router = useRouter()
  const {
    isBetaFlowEnabled,
    localUser,
    status,
    requestBetaAccess,
    isLocalUserBetaAuthorized
  } = useAuth()

  const [
    showBetaAuthorizationAgreementModal,
    setShowBetaAuthorizationAgreementModal
  ] = useState(false)

  const onClickGetStartedDefaultFlow = () => {
    return router.push('/discover')
  }

  const onClickGetStartedBetaAuthorizationFlow = () => {
    if (!localUser) {
      return loginAndPreserveUrlParams(router.query)
    } else {
      return isLocalUserBetaAuthorized
        ? redirectFromSplashPage(router.query)
        : setShowBetaAuthorizationAgreementModal(true)
    }
  }

  useEffect(() => {
    if (isBetaFlowEnabled) {
      showBetaAgreementModalIfUrlParamsRequireAuthorization(
        localUser,
        isLocalUserBetaAuthorized,
        setShowBetaAuthorizationAgreementModal
      )
    }
  }, [router.query, localUser, isLocalUserBetaAuthorized])

  return (
    <OverlayTrigger type="modal">
      <Button
        variant="primary"
        disabled={status === 'CHECKING_STATUS'}
        onClick={
          isBetaFlowEnabled
            ? onClickGetStartedBetaAuthorizationFlow
            : onClickGetStartedDefaultFlow
        }
        slot="trigger"
        size="xl">
        Start designing
      </Button>
      {showBetaAuthorizationAgreementModal && (
        <DialogBase underlay dismissable slot="click-content">
          <Dialog>
            <h2 slot="heading">First things first</h2>
            By clicking Proceed below, you agree and consent to the{' '}
            <Link
              onClick={() =>
                window.open(
                  'https://download.adobeprerelease.com/public/agreement/main/global/PrereleaseAgmtLicense_main_standard_en_20200916.pdf',
                  '_blank'
                )
              }>
              Adobe Prerelease License Agreement
            </Link>
            .
            <div
              className="flex justify-end gap-s"
              style={{
                marginTop: '20px'
              }}>
              <Button
                variant="secondary"
                treatment="outline"
                onClick={() => setShowBetaAuthorizationAgreementModal(false)}>
                Cancel
              </Button>
              <Button variant="accent" onClick={requestBetaAccess as any}>
                Proceed
              </Button>
            </div>
          </Dialog>
        </DialogBase>
      )}
    </OverlayTrigger>
  )
}
export default BetaAuthorizationTrigger

import React from 'react'
import { Theme } from 'ui'
import SpectrumV2Provider from '@react/react-spectrum/Provider'
import styles from './Onboarding.module.scss'
import { useAuth } from '@hooks/useAuth'
import AppBar from './AppBar'
import Hero from './Hero'
import Features from './Features'
import Community from './Community'
import Head from 'next/head'
import { AppNameBeta } from '@constants/appNameBeta'
import Footer from './Footer'

const Onboarding = () => {
  const { status, isAuthenticationStateLoaded } = useAuth()

  return (
    <>
      <Head>
        <title>{`${AppNameBeta} from Adobe 3D & Immersive Labs`}</title>
        <meta
          name="description"
          content="Make illustrations faster with Project Neo"
        />
        <link rel="icon" type="image/svg+xml" href="/Neo.svg" />
      </Head>
      <Theme theme="spectrum" color="light">
        <SpectrumV2Provider theme="light" style={{ background: 'inherit' }}>
          <div className={styles['container']}>
            <header className={styles['header']}>
              <AppBar
                isAuthenticated={status === 'AUTHENTICATED'}
                isAuthenticationStateLoaded={isAuthenticationStateLoaded}
              />
            </header>
            <main className={styles['main']}>
              <Hero />
              <Community />
              <Features />
            </main>
            <footer>
              <Footer />
            </footer>
          </div>
        </SpectrumV2Provider>
      </Theme>
    </>
  )
}

export default Onboarding

import React from 'react'
import styles from './Community.module.scss'
import CarouselProjects from '@components/project/CarouselProjects'
import { useAuth } from '@hooks/useAuth'
import { useGetProjectsQuery } from '@store/graphql/__generated__/schema'

const Community = () => {
  const { status } = useAuth()

  const { data: ProjectsRes } = useGetProjectsQuery({
    skip: status === 'CHECKING_STATUS' || status === 'ERROR_CHECKING_STATUS',
    variables: {
      archived: false,
      public: true,
      featured: true
    }
  })

  return (
    <div className={styles['container']}>
      <h1>Get inspired by the community</h1>
      <CarouselProjects
        className={styles['projects']}
        projects={ProjectsRes?.projects?.entries || []}
      />
    </div>
  )
}

export default Community

import React from 'react'
import styles from './Features.module.scss'

const Cards = [
  {
    title: `Easy to Use`,
    description: `The power to produce stellar icons, lively drawings, and unique dimensional shapes with simple twists, turns, and tweaks in minutes, not hours, using familiar controls and commands you know from Adobe Photoshop and Illustrator.`,
    imgSrc: '/landing-point-building-compr.mp4'
  },
  {
    title: `Made for You`,
    description: `Style, sculpt, and shape from every angle as you build vector & isometric illustrations to create engaging and stimulating graphics. Project Neo gives you more control over color, mid-tones, and shadows by adding depth and geometry to your designs.`,
    imgSrc: '/landing-point-styles-compr.mp4'
  },
  {
    title: `Feeds your Creative Hunger `,
    description: `Meet Creative Cloud's new best friend! Seamlessly integrating with desktop and web apps, it supercharges your creative arsenal. Imagine exporting pristine SVGs and pixel-perfect images for your 2D designs with a snap. Effortlessly combine shapes and play with cutting-edge light and shadow features to revamp your vector graphics. Let's make creativity fun again! `,
    imgSrc: '/landing-point-integration.png'
  }
]

const Features = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['cards']}>
        {Cards.map(({ title, description, imgSrc }) => (
          <div className={styles['card']} key={title}>
            {imgSrc.includes('mp4') ? (
              <video
                className={styles['card-img']}
                autoPlay
                muted
                loop
                playsInline
                preload="none">
                <source src={imgSrc} type="video/mp4" />
                <meta itemProp="name" content="Onboarding features video" />
                <meta itemProp="description" content="Project Neo features" />
              </video>
            ) : (
              <img className={styles['card-img']} src={imgSrc} />
            )}
            <div className={styles['card-content']}>
              <h4 className={styles['card-title']}>{title}</h4>
              <p className={styles['card-description']}>{description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Features

import { useLayoutEffect, useRef, useState } from 'react'

export const useMatchMedia = (query: string) => {
  const mediaQueryRef = useRef<MediaQueryList | null>(null)

  const [isMatching, setIsMatching] = useState(window.matchMedia(query).matches)

  useLayoutEffect(() => {
    const handleMatchMediaChange = (event: MediaQueryListEvent) =>
      setIsMatching(event.matches)

    if (mediaQueryRef.current) {
      mediaQueryRef.current.removeEventListener(
        'change',
        handleMatchMediaChange
      )
    }

    mediaQueryRef.current = window.matchMedia(query)
    mediaQueryRef.current.addEventListener('change', handleMatchMediaChange)
    return () => {
      mediaQueryRef.current?.removeEventListener(
        'change',
        handleMatchMediaChange
      )
    }
  }, [query])

  return isMatching
}

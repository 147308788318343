import React, { ComponentProps, FC, memo, useMemo, useState } from 'react'
import cn from 'classnames'
import { Button, Icon, Theme } from 'ui'
import ArrowLeftIcon from '/public/s2_icon_arrow_left.svg'
import ArrowRightIcon from '/public/s2_icon_arrow_right.svg'
import styles from '@styles/components/CarouselProjects.module.scss'
import { GetProjectsQuery } from '@store/graphql/__generated__/schema'
import ProjectCard from './ProjectCard'
import { ProjectCardSizeLimits } from './ProjectCardSize'

type Projects = Exclude<
  Exclude<GetProjectsQuery['projects'], null | undefined>['entries'],
  null | undefined
>
interface Props {
  adminEditingEnabled?: boolean
  color?: ComponentProps<typeof Theme>['color']
  className?: string
  projects: Projects
  showShareBtn?: boolean
  showReactions?: boolean
}

const CarouselProjects: FC<Props> = ({
  adminEditingEnabled,
  className,
  projects,
  color = 'dark',
  showShareBtn,
  showReactions
}) => {
  const [showLeftButton, setShowLeftButton] = useState(false)
  const [showRightButton, setShowRightButton] = useState(true)

  const memoizedProjects = useMemo(() => {
    return shuffle([...projects])
  }, [projects.length])

  // Maintain order of projects on re-render and only re-render individual projects that has properties changed
  const memoizedShuffledProjects = useMemo(() => {
    return memoizedProjects.map(project => {
      const matchingProject = projects.find(p => p?.uuid === project?.uuid)
      if (!matchingProject) return project

      return {
        ...project,
        ...matchingProject
      }
    })
  }, [projects, memoizedProjects])

  function scroll(direction: 'left' | 'right') {
    const scrollContainer = document.querySelector(
      `.${styles['scroll-content']}`
    )
    if (!scrollContainer) return

    scrollContainer.scrollBy({
      left: direction === 'left' ? -600 : 600,
      behavior: 'smooth'
    })
  }

  function updateScrollIndicators() {
    const scrollContainer = document.querySelector(
      `.${styles['scroll-content']}`
    )
    if (!scrollContainer) return

    const maxScrollLeft =
      scrollContainer.scrollWidth - scrollContainer.clientWidth
    setShowLeftButton(scrollContainer.scrollLeft > 0)
    setShowRightButton(scrollContainer.scrollLeft < maxScrollLeft)
  }

  return (
    <div
      className={cn(styles['container'], className)}
      data-testid="carousel-projects">
      <div className={styles['scroll-container']}>
        <div
          className={cn(styles['scroll-content'], {
            [styles['mask-right']]: !showLeftButton && showRightButton,
            [styles['mask-left']]: showLeftButton && !showRightButton,
            [styles['mask-both']]: showLeftButton && showRightButton
          })}
          onScroll={updateScrollIndicators}>
          {memoizedShuffledProjects.map(project => (
            <div
              style={{ height: ProjectCardSizeLimits.max.height }}
              className={styles['scroll-item-vertical-centered']}>
              <div
                className={cn(styles['scroll-item'], {
                  [styles['scroll-item-drop-shadow']]: color === 'light'
                })}
                key={project?.uuid}>
                <ProjectCard
                  key={project?.uuid}
                  uuid={project?.uuid || ''}
                  name={project?.name || ''}
                  imageUrl={project?.imageUrl || ''}
                  createdAt={project?.insertedAt || ''}
                  public={!!project?.public}
                  featured={!!project?.featured}
                  totalReactions={project?.totalReactions}
                  userReactedWith={project?.userReactedWith}
                  projectCreatorUuid={project?.ownerUserUuid}
                  uniqueReactionTypes={project?.uniqueReactionTypes || []}
                  documentFrameSize={project?.documentFrameSize}
                  linkContainer
                  adminEditingEnabled={adminEditingEnabled}
                  showShareBtn={showShareBtn}
                  showReactions={showReactions}
                />
              </div>
            </div>
          ))}
        </div>
        {showLeftButton && !!projects.length && (
          <Button
            className={cn(styles['scroll-button'], styles['left'], {
              [styles['light-button']]: color === 'dark',
              [styles['dark-button']]: color === 'light'
            })}
            onClick={() => scroll('left')}
            variant={'secondary'}
            icon-only>
            <Icon slot="icon">
              <ArrowLeftIcon />
            </Icon>
          </Button>
        )}
        {showRightButton && !!projects.length && (
          <Button
            className={cn(styles['scroll-button'], styles['right'], {
              [styles['light-button']]: color === 'dark',
              [styles['dark-button']]: color === 'light'
            })}
            onClick={() => scroll('right')}
            variant={'secondary'}
            icon-only>
            <Icon slot="icon">
              <ArrowRightIcon />
            </Icon>
          </Button>
        )}
      </div>
    </div>
  )
}

export default memo(
  CarouselProjects,
  (prevProps, nextProps) =>
    prevProps.adminEditingEnabled === nextProps.adminEditingEnabled &&
    prevProps.showShareBtn === nextProps.showShareBtn &&
    prevProps.color === nextProps.color &&
    prevProps.className === nextProps.className &&
    prevProps.projects.length === nextProps.projects.length &&
    prevProps.showReactions === nextProps.showReactions &&
    hasTotalReactionsChanged(prevProps.projects, nextProps.projects) &&
    areReactionsTheSame(prevProps.projects, nextProps.projects)
)

function shuffle<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

function hasTotalReactionsChanged(
  prevProjects: Projects,
  nextProjects: Projects
): boolean {
  const prevProjectTotalReactions = prevProjects.reduce((acc, curr) => {
    return acc + (curr?.totalReactions || 0)
  }, 0)

  const nextProjectTotalReactions = nextProjects.reduce((acc, curr) => {
    return acc + (curr?.totalReactions || 0)
  }, 0)

  return prevProjectTotalReactions === nextProjectTotalReactions
}

function areReactionsTheSame(
  prevProjects: Projects,
  nextProjects: Projects
): boolean {
  if (prevProjects.length !== nextProjects.length) return true

  return prevProjects.every((project, index) => {
    return (
      project?.uniqueReactionTypes?.sort().join(' ') !==
      nextProjects[index]?.uniqueReactionTypes?.sort().join(' ')
    )
  })
}

import React from 'react'
import styles from './Hero.module.scss'

import { useMatchMedia } from '@hooks/useMatchMedia'
import BetaAuthorizationTrigger from './BetaAuthorizationTrigger'

import AnthoCam from 'public/landing_antho_cam.png'
import Camera from 'public/landing_camera.png'
import Heart from 'public/landing_heart.png'
import PianoCute from 'public/landing_piano_cute.png'
import RainbowGirl from 'public/landing_rainbow_girl.png'
import RobotHead from 'public/landing_robot_head.png'

const Hero = () => {
  const isSmallDevice = useMatchMedia('(max-width: 597px)')

  const imageSources = [
    AnthoCam,
    Camera,
    Heart,
    PianoCute,
    RainbowGirl,
    RobotHead
  ]

  const renderImages = () =>
    imageSources.map(image => (
      <img key={image.src} src={image.src} alt="Hero images" />
    ))

  return (
    <div className={styles['container']}>
      <div className={styles['content-container']}>
        <div className={styles['content-alignment-container']}>
          <div className={styles['content']}>
            <img src="/Neo.svg" alt="Neo logo" />
            <div className={styles['content-text']}>
              <h1>Make illustrations faster with Project Neo</h1>
              <p>Supercharge 2D designs with 3D magic</p>
            </div>
            <BetaAuthorizationTrigger />
          </div>
        </div>
        {isSmallDevice ? (
          <div className={styles['img-container']}>{renderImages()}</div>
        ) : (
          // May be replaced with a video in the future
          <div className={styles['img-container']}>{renderImages()}</div>
        )}
      </div>
    </div>
  )
}

export default Hero

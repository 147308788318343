import React, { FC } from 'react'
import { Button, ProgressCircle } from 'ui'
import styles from './AppBar.module.scss'
import AdobeIcon from '/public/adobe.svg'
import AdobeIMS from '@services/auth/IMS'
import UserProfile from '@components/authentication/UserProfile'
import AppLogo from '@components/appLogo/AppLogo'

const login = () => AdobeIMS.signIn({ redirect_uri: window.location.href })

interface Props {
  isAuthenticated: boolean
  isAuthenticationStateLoaded: boolean
}

const AppBar: FC<Props> = ({
  isAuthenticated,
  isAuthenticationStateLoaded
}) => {
  const UserControl = () => {
    if (!isAuthenticationStateLoaded) {
      return <ProgressCircle indeterminate size="s" />
    }

    return !isAuthenticated ? (
      <Button variant="primary" onClick={login} size="l">
        Log In
      </Button>
    ) : (
      <div className={styles['user-profile-container']}>
        <UserProfile themeColor={{ trigger: 'light', dialog: 'light' }} />
      </div>
    )
  }

  return (
    <div className={styles['container']}>
      <nav className={styles['nav']}>
        <AppLogo logo="adobe" />
        <UserControl />
      </nav>
    </div>
  )
}

export default AppBar
